<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="videos"
    :show-actions="false"
    :show-pagination="false"
    :current-page="1"
    :on-page-change="() => {}"
    table-css-class="video-table"
    >
    <template #tableBody="{ item, index }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="getSiteNames(item)"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.authors ? item.authors.join(', ') : ''"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.title"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="item.videoDuration ? $options.filters.formatMilliseconds(item.videoDuration) : ''"
      />
      <TitleColumn
        :title-header="tableHeaders[4].name"
        :title-value="item.videoPlays | formatNumber"
      />
      <TitleColumn
        :title-header="tableHeaders[5].name"
        :title-value="item.avgPassPerc ? `${item.avgPassPerc} %` : ''"
      />
      <AbstractColumn
        v-for="column in [
          { headerIndex: 6, prop: 'hero' },
          { headerIndex: 7, prop: 'article' },
          { headerIndex: 8, prop: 'gallery' }
        ]"
        :key="`row-${index}-column-${column.headerIndex}`"
        :header="tableHeaders[column.headerIndex].name"
      >
        <div v-if="index === videos.length - 1" class="abstract-data-table__td-title">
          {{ item[column.prop] | formatNumber }}
        </div>
        <div v-else>
          <div
            v-for="e in item[column.prop]"
            :key="`${column.prop}-${item.title}-${e.brand}-${e.videoPlays}`"
            class="label label-light-inverse">
            {{ e.brand }} - <strong>{{ e.videoPlays | formatNumber }}</strong>
          </div>
        </div>
      </AbstractColumn>
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import AbstractColumn from '@/components/table/columns/AbstractColumn'

export default {
  name: 'VideoStatsDailyReportTable',
  props: {
    videos: {
      type: Array,
      required: true
    }
  },
  components: {
    AbstractColumn,
    TitleColumn,
    AbstractDataTable
  },
  computed: {
    tableHeaders () {
      return [
        { name: this.$t('videoStats.daily_report.site') },
        { name: this.$t('videoStats.daily_report.author') },
        { name: this.$t('videoStats.daily_report.title') },
        { name: this.$t('videoStats.daily_report.video_duration') },
        { name: this.$t('videoStats.daily_report.play_request') },
        { name: this.$t('videoStats.daily_report.average') },
        { name: this.$t('videoStats.daily_report.hero') },
        { name: this.$t('videoStats.daily_report.article') },
        { name: this.$t('videoStats.daily_report.gallery') }
      ]
    }
  },
  methods: {
    getSiteNames (item) {
      if (!item.brands) {
        return ''
      }
      return item.brands.map(brand => this.$store.getters['site/getSiteTitle'](brand)).join(', ')
    }
  }
}
</script>
<style lang="scss">
  .video-table {
    &__thead,
    &__tr {
      @include bp(0 16) {
        grid-template-columns: rem(100px) rem(100px) auto rem(50px) rem(50px) rem(80px) rem(70px) rem(70px) rem(70px);
      }
      @include bp(16) {
        grid-template-columns: rem(150px) rem(180px) auto rem(80px) rem(80px) rem(110px) rem(100px) rem(100px) rem(100px);
      }
    }
  }
</style>
