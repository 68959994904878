var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AbstractDataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.videos,"show-actions":false,"show-pagination":false,"current-page":1,"on-page-change":function () {},"table-css-class":"video-table"},scopedSlots:_vm._u([{key:"tableBody",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('TitleColumn',{attrs:{"title-header":_vm.tableHeaders[0].name,"title-value":_vm.getSiteNames(item),"subtitle":""}}),_vm._v(" "),_c('TitleColumn',{attrs:{"title-header":_vm.tableHeaders[1].name,"title-value":item.authors ? item.authors.join(', ') : ''}}),_vm._v(" "),_c('TitleColumn',{attrs:{"title-header":_vm.tableHeaders[2].name,"title-value":item.title}}),_vm._v(" "),_c('TitleColumn',{attrs:{"title-header":_vm.tableHeaders[3].name,"title-value":item.videoDuration ? _vm.$options.filters.formatMilliseconds(item.videoDuration) : ''}}),_vm._v(" "),_c('TitleColumn',{attrs:{"title-header":_vm.tableHeaders[4].name,"title-value":_vm._f("formatNumber")(item.videoPlays)}}),_vm._v(" "),_c('TitleColumn',{attrs:{"title-header":_vm.tableHeaders[5].name,"title-value":item.avgPassPerc ? ((item.avgPassPerc) + " %") : ''}}),_vm._v(" "),_vm._l(([
        { headerIndex: 6, prop: 'hero' },
        { headerIndex: 7, prop: 'article' },
        { headerIndex: 8, prop: 'gallery' }
      ]),function(column){return _c('AbstractColumn',{key:("row-" + index + "-column-" + (column.headerIndex)),attrs:{"header":_vm.tableHeaders[column.headerIndex].name}},[(index === _vm.videos.length - 1)?_c('div',{staticClass:"abstract-data-table__td-title"},[_vm._v("\n        "+_vm._s(_vm._f("formatNumber")(item[column.prop]))+"\n      ")]):_c('div',_vm._l((item[column.prop]),function(e){return _c('div',{key:((column.prop) + "-" + (item.title) + "-" + (e.brand) + "-" + (e.videoPlays)),staticClass:"label label-light-inverse"},[_vm._v("\n          "+_vm._s(e.brand)+" - "),_c('strong',[_vm._v(_vm._s(_vm._f("formatNumber")(e.videoPlays)))])])}),0)])})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }